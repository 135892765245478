<template>
  <div>
    <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
      <div
        :style="{'margin-left': 25 * depth + 'px'}"
      >
        <div class="d-flex justify-content-between">
          <div class="node" @click="expanded = !expanded">
            <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658' }}</span>
            <span v-if="node.type == 'group'">{{ node.name }}</span>
          </div>
        </div>
        <div class="table-responsive mt-2" v-if="expanded && endpointChildren.length > 0" >
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th class="bg-white" rowspan="2">Budget Head</th>
                <th class="bg-white" rowspan="2">Description</th>
                <th class="bg-white" rowspan="2">Linked Account Head</th>
                <th
                  class="bg-white text-center"
                  colspan="4"
                  v-for="(value, month) in months"
                >
                  {{month}}
                </th>
              </tr>
              <tr>
                <slot v-for="month in months">
                  <th class="bg-white">Budget</th>
                  <th class="bg-white">Actual</th>
                  <th class="bg-white">Variance</th>
                  <th class="bg-white">% of Budget</th>
                </slot>
              </tr>
            </thead>
            <tbody>
              <tr v-for="child in endpointChildren">
                <td style="min-width: 300px">{{child.name}}</td>
                <td>{{child.description ?? ''}}</td>
                <td>{{child.linked_account ?? ''}}</td>
                <slot v-for="(value, month) in months">
                  <td class="text-right">
                    {{ commaFormat(budget_v_actual[child.budget_general?.id][month].budget ?? 0) }}
                  </td>
                  <td class="text-right">
                    {{ commaFormat(budget_v_actual[child.budget_general?.id][month].actual ?? 0) }}
                  </td>
                  <td class="text-right">
                    {{ commaFormat(budget_v_actual[child.budget_general?.id][month].variance ?? 0) }}
                  </td>
                  <td class="text-right">
                    {{ commaFormat(budget_v_actual[child.budget_general?.id][month].budget_percent ?? 0) }}%
                  </td>
                </slot>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr class="mb-1 mt-0" v-if="type == 'group'">
    <template v-if="expanded && groupChildren.length > 0">
      <TreeBrowser
        v-for="item in groupChildren"
        :key="item.name"
        :parent="node"
        :depth="depth + 1"
        :node="item"
        :months="months"
        :budget_v_actual="budget_v_actual"
      />
    </template>
  </div>
</template>

<script setup>
import {computed, inject, ref} from 'vue';
import figureFormatter from "@/services/utils/figureFormatter";

const $props = defineProps( {
  node: {},
  depth: {
    type: Number,
    default: 0
  },
  parent: {},
  months: {},
  budget_v_actual: {}
});

const emitter = inject('emitter');

let expanded = ref(true);
const {commaFormat} = figureFormatter()

const hasChildren = computed(() => {
  return $props.node.child_with_budget?.length;
});

const type = computed(() => {
  return $props.node.type;
});

const budgetGeneralId = computed(() => {
  return $props.node.budget_general?.id;
})

const groupChildren = computed(() => {
  return $props.node.child_with_budget?.length > 0 ?
         $props.node.child_with_budget.filter((child) => {
           return (child.type === 'group')
         })
        : [];
})

const endpointChildren = computed(() => {
  return $props.node.child_with_budget?.length > 0 ?
         $props.node.child_with_budget.filter((child) => {
           return (child.type === 'endpoint')
         })
        : [];
})

const parentL = computed(() => {
  return parent.child_with_budget?.length ? parent.child_with_budget?.length : 0;
});

const last = computed(() => {
  return parent.child_with_budget ? parent.child_with_budget[parentL - 1] : {};
});

</script>


<style scoped>
.bg-black-light {
  background: #00000024;
}

.rounded-8 {
  border-radius: 8px;
}

.node {
  cursor: pointer;
}

.node-hover {
  padding: 10px 2px;
}

.node-hover:hover {
  background: #fffeec;
}

.cursor-initial {
  cursor: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.w-30 {
  width: 30px;
}

.w-20 {
  width: 20px;
}

.z-999 {
  z-index: 999;
}

.w-44 {
  width: 44px;
}
</style>