<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
        :showBtn="false"
        :showAddNew="false"
        title="Budget vs Actual Report"
      />
      <div class="row match-height mt-1">
        <div class="col-12 col-sm-6 col-md-4">
          <label for="colFormLabel" >Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
            @option:selected="onSelectProject"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <label for="colFormLabel" >Select Donor</label>
          <v-select
            placeholder="Select Donor"
            :options="donors"
            label="name"
            :reduce="name => name.id"
            v-model="donorId"
            @option:selected="onSelectDonor"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <label for="colFormLabel" >Select Budget</label>
          <v-select
            placeholder="Select Budget"
            :options="budgets"
            v-model="budgetMasterId"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </div>
      <div class="row match-height mt-1">
        <DateQuerySetter
          @onClickGo="getBudgetVsActual"
        >
        </DateQuerySetter>
      </div>
    </div>
    <div class="card-body">
      <div class="row d-flex flex-wrap justify-content-between align-items-center">
        <div class="col-12 text-right">
          <button
            style="min-width: 64px;"
            @click="exportReport"
            class="btn btn-primary btn-block waves-effect waves-float waves-light"
          > Export
          </button>
        </div>
        <TreeBrowser
          :style="{opacity: isLoading ? .5 : 1}"
          v-for="(item, i) in budgetDetails"
          :key="i"
          :node="item"
          :months="months"
          :budget_v_actual="budget_v_actual"
        />
      </div>
    </div>
    <div class="mb-2"></div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script setup>
  import TitleButton from "@/components/atom/TitleButton";
  import {inject, onMounted, ref} from "vue";
  import {useRoute} from "vue-router";
  import handleProjects from "@/services/modules/procurement/project";
  import Loader from '@/components/atom/LoaderComponent'
  import DateQuerySetter from "@/components/atom/DateQuerySetter";
  import handleBudgets from "@/services/modules/procurement/budget";
  import TreeBrowser from "@/views/page/company/procurement/budget/report/project-bva/TreeBrowser";

  const $route = useRoute();
  const showError = inject('showError');
  const query = `?company_id=${$route.params.companyId}`;
  const projects = ref([]);
  const donors = ref([]);
  const budgets = ref([]);
  const months = ref([]);
  const budget_v_actual = ref({});
  const budgetDetails = ref(null);
  const projectId = ref(null);
  const donorId = ref(null);
  const budgetMasterId = ref(null);
  const isLoading = ref(false);
  const token = localStorage.getItem('token');
  const {fetchProjects, fetchProjectDonors, fetchDonorBudgets} = handleProjects();
  const {fetchBudgetVsActual} = handleBudgets();

  const onSelectProject = () => {
    isLoading.value = true;
    fetchProjectDonors(projectId.value, query)
      .then( res => {
        if(res.status) {
          donorId.value = null;
          budgetMasterId.value = null;
          donors.value = res.data;
        } else {
          showError(res.message)
        }
      })
      .catch( err => {
        showError(err.response.message);
      })
      .finally( () => isLoading.value = false)
  }

  const onSelectDonor = () => {
    isLoading.value = true
    let budgetQuery = query + `&project_id=${projectId.value}`;
    fetchDonorBudgets(donorId.value, budgetQuery)
      .then( res => {
        if (res.status) {
          budgetMasterId.value = null;
          budgets.value = res.data;
        } else {
          showError(res.message);
        }
      })
      .catch( err => {
        showError(err.response.message);
      })
      .finally( () => isLoading.value = false)
  }

  const getBudgetVsActual = () => {
    if(budgetMasterId.value === null)
    {
      showError('Budget is required for this report')
      return;
    }
    isLoading.value = true;
    budgetDetails.value = [];
    months.value = [];
    budget_v_actual.value = {};
    let budgetQuery = query + `&start_date=${$route.query.start}&end_date=${$route.query.end}`;
    fetchBudgetVsActual(budgetMasterId.value, budgetQuery)
      .then( res => {
        if (res.status) {
          budgetDetails.value = res.data.project_accounts;
          months.value = res.data.period;
          budget_v_actual.value = res.data.budget_v_actual;
        } else {
          showError(res.message);
        }
      })
      .catch( err => {
        showError(err.response.message);
      })
      .finally( () => isLoading.value = false)
  }

  const exportReport = () => {
    if(budgetMasterId.value === null){
      showError('Budget is required for this report');
      return
    }
    let url = `${process.env.VUE_APP_BASE_URL}/export/project/budget-vs-actual/${budgetMasterId.value}${query}`
              + `&start_date=${$route.query.start}&end_date=${$route.query.end}&_token=${token}`;
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.click();
  }

onMounted( () => {
  isLoading.value = true;
  fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      } else {
        showError(res.message)
      }
    })
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})

</script>
